
import './Roadmap.css';
import React from 'react'



function Roadmap() {
  return (
    <>
    <section className='timeline-section'>
        <h1 id="roadmap-header" className="roadmap-header">ROADMAP</h1>
        <div className="line cntr"></div>
        <div class="timeline-items">
            <div class="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date"></div>
                <div class="timeline-content">
                    <h2>PHASE 0.0_ OCULUS GIVEAWAY #1</h2>
                    <p>First 10 DUST NFTs + one Oculus Quest 2 VR headset.<br/>Twitter + Disccord giveaway, 10 members in total</p>
                </div>
            </div>
            <div class="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date"></div>
                <div class="timeline-content">
                    <h2>PHASE 0.1_ ANCIENT CHIP</h2>
                    <p>222 free NTFs that grants special benefits to its holders as its powers are unlocked.</p>
                </div>
            </div>
            <div class="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date"></div>
                <div class="timeline-content">
                    <h2>PHASE 0.2_ GRILLZ GANG GIVEAWAY</h2>
                    <p>Grillz #4007 NFT raffle for Ancient Chip holders.
                    </p>
                </div>
            </div>
            <div class="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date"></div>
                <div class="timeline-content">
                    <h2></h2>
                    <p>
                    <br />
                    <br />
                    <br />
                    </p>
                </div>
            </div>
            <div class="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date"></div>
                <div class="timeline-content">
                    <h2></h2>
                    <p>
                    <br />
                    <br />
                    <br />
                    </p>
                </div>
            </div>
            <div class="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date"></div>
                <div class="timeline-content">
                    <h2>PHASE 0.5_ BIOMES</h2>
                    <p>First 10 DUST Biome NFTs will be up for bidding on OpenSea for 24 hours auction.
                    <br />
                    1 day = 1 biome = 10 days in total. Highest bid wins, and the Biome holder becomes a @LORD.<br />
                    We will reveal what are the biomes for later, there also will be at least one more Biome-bidding-drop in the future.
                    <br />
                    </p>
                </div>
            </div>
            <div class="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date"></div>
                <div class="timeline-content">
                    <h2>PHASE 1.0_ CHIP HOLDERS PRESALE</h2>
                    <p>Ancient Chip holders get to mint max 5 elements per wallet.<br />
                    Mint 5 to get one extra for free
                    </p>
                </div>
            </div>
            <div class="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date"></div>
                <div class="timeline-content">
                    <h2></h2>
                    <p> 
                    <br />
                    <br />
                    <br />
                    </p>
                </div>
            </div>
            <div class="timeline-item">
                <div className="timeline-dot"></div>
                <div className="timeline-date"></div>
                <div class="timeline-content">
                    <h2>PHASE 1.2_ PUBLIC SALE</h2>
                    <p>10 000 elements (minus the ones sold in presale phase) will be available to mint for 5 days and 5 days only. All of the remaining (unsold) elements will be burned into $DUST and sent to element holders (amount sent depends on the elements amount in your wallet).
                    
                    </p>
                </div>
            </div>
            
        </div>
        
    </section>
    <div className="space">
        <p>FOR MORE INFORMATION</p>
        <p>JOIN OUR <a href="https://discord.com/invite/qNZ2KptuNV" target="_blank" rel="noreferrer">DISCORD</a> AND FOLLOW OUR <a href="https://twitter.com/dust_nft" target="_blank" rel="noreferrer">TWITTER</a></p>
    </div>
    </>
  );
}

export default Roadmap;


/*
- PHASE 0.0_ OCULUS GIVEAWAY #1
First 10 DUST NFTs + one Oculus Quest 2 VR headset.

- PHASE 0.1_ ANCIENT CHIP
222 free NTFs that grants special benefits to its holders as its powers are unlocked.

- PHASE 0.2_ GRILLZ GANG GIVEAWAY 
Grillz #4007 NFT raffle for Ancient Chip holders.

- PHASE 0.3_ GRILLZ GANG WHITELIST
- True OG Badge holders (555 badges) - max 3 NFTs per holder;
- 55 whitelist giveaway (GG server open raffle) - 1 NFT for each winner.

- PHASE 0.4_ 888 INNER CIRCLE WHITELIST
- 888 special (about 300 holders have the min 8 to qualify) - max 3 mint;
- 88 whitelist giveaway (done via raffle in 888 server for ANY holder) - max 1 mint.

- PHASE 0.5_ BIOMES
First 10 DUST Biome NFTs will be up for bidding on OpenSea for 24 hours auction. 
1 day = 1 biome = 10 days in total. Highest bid wins, and the Biome holder becomes a @LORD.
We will reveal what are the biomes for later, there also will be at least one more Biome-bidding-drop in the future.

*

- PHASE 1.0_ CHIP HOLDERS PRESALE
CHIP holders (222 cards) - max 6 mints.
Price: 0.05 ETH + gas / element.

- PHASE 1.1_ GG + 888 PRESALE
Max 3 mints. 
Price: 0.05 ETH + gas / element.

- PHASE 1.2_ PUBLIC SALE
10 000 elements (minus the ones sold in presale phase) will be available to mint for 5 days and 5 days only. All of the remaining (unsold) elements will be burned into $DUST and sent to element holders (amount sent depends on the elements amount in your wallet). 
Price: 0.05 ETH + gas / element.
*/