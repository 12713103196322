import React from 'react';
import '../HeroSectionMint.css';
import '../Loader.css';
import { Button } from '../Button';
import Navbar from '../Navbar';

export default function Chip(props) {
  if(props.mintStatus===''){
  
    return(
      <>
      <Navbar account={props.account} wallet={props.wallet}/>
      <div className='hero-container chip'>
        {props.wallet.status === 'connected' ? (
          <>
          <div className="paragraph small">GET YOUR FREE ANCIENT CHIP CARD NOW</div>
          
          <div className="paragraph large">{props.supply} OF 222 MINTED</div>
          <br />
          
          {props.supply <222 ?
          <Button className='btns' buttonStyle='mint' buttonSize='btn--large' onClick={props.mint}>M I N T</Button>
          :
          <Button className='btns' buttonStyle='mint' buttonSize='btn--large' >S O L D &nbsp;O U T</Button>
          }
          </>
        ) : (
          <p></p>
        )}
        
      </div>  
      </>
    );
  }
  else if(props.mintStatus==='confirmation'){
    return (
      <>
      <Navbar account={props.account} wallet={props.wallet}/>
      <div className='hero-container chip'>

        <div className="spacer"></div>
          
        <h2>Confirm transaction in your MetaMask wallet.</h2>
        <div className="loader">
          <div className="face">
            <div className="circle"></div>
          </div>
          <div className="face">
            <div className="circle"></div>
          </div>
        </div>
      </div>
      </>
    );
  }
  else if(props.mintStatus==='inprogress'){
    
    let etherscan = "https://etherscan.io/tx/" + props.txHash
    return (
      <>
      <Navbar account={props.account} wallet={props.wallet}/>
      <div className='hero-container inprogress'>
        
        <div className="spacer"></div>
          
        <div className="top">Transaction is in progress.</div>
        <div className="loader">
          <div className="face">
            <div className="circle"></div>
          </div>
          <div className="face">
            <div className="circle"></div>
          </div>
        </div>
        <div className="paragraph bottom">SEE YOUR TRANSACTION DETAILS ON ETHERSCAN:  
          <br/>
          <a href={etherscan} className=" link" target="_blank"> {props.txHash}</a>
        </div>
    
      </div>
      </>
    );
  }
  else if(props.mintStatus==='success'){
    let etherscan = "https://etherscan.io/tx/" + props.txHash
    return (
      <>
       <Navbar account={props.account} wallet={props.wallet}/>
      <div className='hero-container minted'> 
      
        {/* <video id="hero-video" autoPlay muted loop playsInline >
          <source src="/videos/ANCIENT-CHIP.mp4" type='video/mp4'/>
          <p className="ppp">This is fallback content to display for user agents that do not support the video tag.</p>
        </video>   */}
        
          
        <div className="large-minted">M I N T E D</div>
        <div className="paragraph bottom">SEE YOUR TRANSACTION DETAILS ON ETHERSCAN:  
          <br/>
          <a href={etherscan} className=" link" target="_blank"> {props.txHash}</a>
        </div>
      </div>
      </>
    );
  }
  else{
    return (
      <>
       <Navbar account={props.account} wallet={props.wallet}/>
      <div className='hero-container chip'>
        {/* <video id="hero-video" autoPlay muted loop playsInline >
          <source src="/videos/ANCIENT-CHIP.mp4" type='video/mp4'/>
          <p className="ppp">This is fallback content to display for user agents that do not support the video tag.</p>
        </video>   */}
        <div className="spacer"></div>
          
        <h2>{props.mintStatus}</h2>
              
      </div>
      </>
    )
    
  }
  
}
