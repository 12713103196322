import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';


function HeroSection(props) {
  return (
    <div className='hero'>
      
      <video id="hero-video" autoPlay muted loop >
        <source src="/videos/HERO.webm" type='video/webm'/>
        <p>This is fallback content to display for user agents that do not support the video tag.</p>
      </video>  
      
      <p>OCULUS QUEST 2 VR NFT</p>
      <p>NOVEMBER 2021</p>
      <br/>
      <div className="scroll-icon"></div>
      
      
      
    
      
    </div>
  );
}

export default HeroSection;
