import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './Navbar.css';


function Navbar(props) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const connectMobileWallet = async() => {
    setClick(false);
    const networkId = await window.ethereum.request({ method: 'eth_chainId' });
    if(networkId === "0x1"){
      props.wallet.connect()
    }
    else{
      alert("Contract not deployed to the selected network. Please switch to mainnet ethereum network and try again.")
    }
  }

  const connectWallet = async () => {
    const networkId = await window.ethereum.request({ method: 'eth_chainId' });
    if(networkId === "0x1"){
      props.wallet.connect()
    }
    else{
      alert("Contract not deployed to the selected network. Please switch to mainnet ethereum network and try again.")
    }
  }

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  console.log(props.address);
  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <div  className='navbar-logo' onClick={closeMobileMenu}>
            {/* D&nbsp;&nbsp;U&nbsp;&nbsp;S&nbsp;&nbsp;T */}
            
          </div>
          <div className='menu-icon' onClick={handleClick}>
            <div className={click ? 'close' : 'open'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li>
            <div className="social-container-nav mobile ">
                <a href="https://discord.gg/qNZ2KptuNV" target="_blank">
                  <span className='social social-icon-link discord' aria-label='Discord'>
                    {/* <i class='fab fa-discord' /> */}
                    <div className="discord"></div>
                  </span>
                </a>
                <a href="https://twitter.com/dust_nft" target="_blank">
                  <span className='social social-icon-link twitter' aria-label='Twitter'>
                    <div className="twitter"></div>
                  </span>
                </a>
              </div>
            </li>
            <li>
            {props.wallet.status === 'connected' ? (
                <>
                <p className="address-mobile">{props.wallet.account}</p>
                </>
              ) : (
                <>
                <div className="mobile-btn">
                <Button buttonStyle='btn--outline' buttonSize='btn--mobile'  onClick={connectMobileWallet}>connect MetaMask</Button> 
                </div>
                </>
              )}
            </li>
            <li className="address">
            {/* {props.account} */}
            </li>
           
          </ul>
            <div className="right">
              {props.wallet.status === 'connected' ? (
                <>
                <p className="address">{props.wallet.account}</p>
                </>
              ) : (

                <Button buttonStyle='btn--outline' onClick={ connectWallet}>connect MetaMask</Button> 
              )}
              <div className="social-container-nav">
                <a href="https://discord.gg/qNZ2KptuNV" target="_blank">
                  <span className='social social-icon-link discord' aria-label='Discord'>
                    {/* <i class='fab fa-discord' /> */}
                    <div className="discord"></div>
                  </span>
                </a>
                <a href="https://twitter.com/dust_nft" target="_blank">
                  <span className='social social-icon-link twitter' aria-label='Twitter'>
                    <div className="twitter"></div>
                  </span>
                </a>
              </div>
            </div>
          
        </div>
      </nav>
    </>
  );
}

export default Navbar;
