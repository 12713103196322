// import 'bootstrap/dist/css/bootstrap.min.css';


import { BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';

import React, { useState, useEffect, useRef} from 'react';
import { useWallet, UseWalletProvider } from 'use-wallet'
import Web3 from 'web3';
// import dust from './abis/Dust.json'
import DustMintPass from './abis/DustMintPass.json'
import AncientChip from './abis/AncientChip.json'
import Navbar from './components/Navbar';
// import HeroSectionMint from './components/HeroSectionMint';
import HeroSection from './components/HeroSection';

// // import HeroSectionCountdown from './components/HeroSectionCountdown';
import Footer from './components/Footer';
import AncientChipComp from './components/AncientChip';
import Roadmap from './components/Roadmap';
import Who from './components/Who'
import Trailer from './components/Trailer'

import Landing from './components/Landing'
import Chip from './components/pages/Chip'

function App (){
  var fitToRange = require('fit-to-range');


  const [account, setAccount] = useState('');
  const [contract, setContract] = useState(null);
  const [totalSupply, setTotalSupply] = useState(0);



  const [chipsSupply, setChipsSupply] = useState(0);
  const [mintStatus, setMintStatus] = useState('');
  const [txHash, setTxHash] = useState('');

  
  const ethereum = window.ethereum;
  const wallet = useWallet()
  console.log(mintStatus)
  

  useEffect(() => {
    initWeb3();
    supply();
  }, []);


  

 // ANCIENT CHIP ->
  const initWeb3 = async() =>{
    if(window.ethereum){
      const networkId = await window.ethereum.request({ method: 'eth_chainId' });
      
      const networkData = DustMintPass.networks[parseInt(networkId)];
      const web3 = new Web3(window.ethereum);
      web3.eth.handleRevert = true;
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      setAccount(accounts[0]);
      const abi = AncientChip.abi;
      const address = "0x8B56b4C300d58E7f5694dd8EC35aee9Dd054881D";
      const contractx = new web3.eth.Contract(abi, address);
      setContract(contractx);
      console.log(web3.eth.handleRevert);
      await contractx.methods.totalMinted().call().then(function(result){setChipsSupply(result)});
    }
      
  }
  const mintChip = async(e) =>{
    const networkId = await window.ethereum.request({ method: 'eth_chainId' });
    if(networkId !=="0x1"){
      alert("Contract not deployed to the selected network. Please switch to mainnet ethereum network and try again.")

      return
    }
    if(contract) {
      contract.options.handleRevert = true;
      var gas = 300000;
      
      contract.methods.mintChip().estimateGas({from: wallet.account, value: 0}).then(function(gasAmount){
        gas = gasAmount;
        console.log(gas);
        setMintStatus('confirmation');
        
        
        contract.methods.mintChip().send({from: wallet.account, gas:gas, value: 0}, function(error, transactionHash){ console.log(error)})
        .on('transactionHash', function(hash){
          setTxHash(hash)
          setMintStatus('inprogress')
         
        })
        .on('confirmation', function(confirmationNumber, receipt){
          if(receipt.status===false){
            setMintStatus('Transaction failed')
 
            return;
          }else{
            setMintStatus('success')

            supply()
          }
        }).on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
          setMintStatus('Transaction failed')

        }).catch(function(e){
          setMintStatus('Transaction failed')

          console.log(e)
        });
      }).catch(function(err){
        console.log(err.message.split("{")[0])
        setMintStatus(err.message.split("{")[0])

        gas = 300000;
      })

      

      
      
    }else {
      window.alert('Smart contract not deployed to detected network.')
    }
  }    
  async function supply(){
    if(contract) {
      console.log("supply updated")
      await contract.methods.totalMinted().call().then(function(result){setChipsSupply(result)});
    }
  }
  


  const[count, updateCount] = useState(1);

  function counterUpdate(n){
    console.log(count);
    if(count + n >= 1 && count + n <= 5){
      updateCount(count => count + n);
      console.log(count);
    }
  }
  

    return (
      <>
      
      <Router>
        <Navbar account={account} wallet={wallet}/>
        <Switch>
          <Route path="/chip" >
            <Chip account={account} wallet={wallet} mint={() => {mintChip()}} mintStatus={mintStatus} txHash = {txHash} supply = {chipsSupply}/>
          </Route>
          <Route path="/" >
            {/* <HeroSectionMint totalSupply={totalSupply} mint={() => {mintChip()}} counterUpdate = {(n) => counterUpdate(n)} count ={count}/> */}
            <HeroSection />
            <Who />
            <Roadmap />
            <Trailer />
            <AncientChipComp />
            <Footer />
            {/* <Landing /> */}
          </Route>
        </Switch>
        
      </Router> 
    
    {/* <Landing /> */}
      </>
    );
} 


export default () => (
  <UseWalletProvider
    chainId={3}
    connectors={{
      // This is how connectors get configured
      portis: { dAppId: 'thedustxyz' },
    }}
  >
    <App />
  </UseWalletProvider>
)