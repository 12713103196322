
import './Trailer.css';
import React from 'react'



function Trailer() {
  return (
    <>
    <div className="trailer-container" >
        <br/>
        
        <div className="trailer-header">ANNOUNCEMENT TRAILER</div>
        <div className="line"></div>
        <br/>
        <div className="player">
            <iframe 
                src="https://www.youtube.com/embed/cHv1Ax6CQ04?controls=0">
            </iframe>
        </div>
        <br/>
        <div className="below">There are five different core elements. </div>
        <div className="below">Which one is the most powerful? Is it possible to have all the power? To be undefeated? To be unstoppable?<br/>To control all the elements?</div>
        <div className="below">Does an almighty elemental exist? You might be one of them...</div>
        
    </div>
    </>
  );
}

export default Trailer;
