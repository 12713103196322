import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <small class='website-rights'>COMING SOON / NOVEMBER 2021</small>
    </div>
  );
}

export default Footer;
