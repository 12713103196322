
import './Who.css';
import React from 'react'



function Who() {
  return (
    <>
    <div className="who-container" >
        <div id="who-header" className="who-header">WHO WE ARE</div>
        <div className="line"></div>
        <br/>
        <div className="description">We are the ancient elemental council. We are now. We were before. We are everything that will remain. Become one of us.</div>
        <div className="description">You know us, and we know you. We are all made of the five elements. Metaverse elements.<br/>When you’ll collect all five, our form will be revealed. Be ready.</div>
        <div className="description">We are.</div>
      
    </div>
    <div className="after-who" ></div>
    
    </>
  );
}

export default Who;
