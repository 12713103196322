
import './AncientChip.css';
import React from 'react'



function AncientChip() {
  return (
    <>
    <div className="chip-img-top"></div>
    <div className="chip-container" >
        <div className="chip-header">WHAT THE [ANCIENT CHIP] IS?</div>
        <div className="line"></div>
        <br/>
        <div className="description">	
        The Ancient Chip is a relic with special powers that was left behind by a now vanished civilization. What powers does it hold? As more and more Ancient Chips get spread into the hands of DUST followers, the powers will slowly be revealed to those who hold it. There are only 222 Ancient Chips known to exist, so be one of the first discoverers and benefit as its powers are unlocked. 
        <a className="link os"href="https://opensea.io/collection/dust-ancient-chip" target="_blank" rel="noreferrer"> View the collection on OpenSea</a>
        </div>
        <div className="center">
            <div className="img one"></div>
            <div className="img two"></div>
            <div className="img three"></div>
        </div>
        <div className="description">Owning this NFT not only gives you a special Discord role with a secret Ancient channel, but also an opportunity to win other NFTs, receive exclusive airdrops, get access to the Elements and PFP Elementals presales, be a part of the VR experience beta and many more.</div>
        <div className="description">The only question is: are you worthy?</div>

        <div className="chip-img-bottom"></div>
        


    </div>
    
    
    </>
  );
}

export default AncientChip;
